import React, { useEffect } from 'react';
import { Box, Button, Typography } from "@mui/material";
import { defaultGuest, setRSVPState, useBookingName, useIsExistingRSVP, useIsLoading } from '../state/rsvp';
import { useNavigate } from 'react-router-dom';
import { Links } from '../links';
import { useDispatch } from 'react-redux';

export const HaveYouRSVPd = () => {
  const isLoading = useIsLoading();
  const isExistingRSVP = useIsExistingRSVP();
  const name = useBookingName()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [isRSVPd, setIsRSVPd] = React.useState(false);
  useEffect(() => {
    if (!isLoading && !isExistingRSVP) {
      navigate(Links.Email);
    }
  }, [isLoading, isExistingRSVP, navigate]);

  const onIsNewBooking = () => {
    navigate(Links.Email);
    dispatch(setRSVPState({
      firstname: name.firstname,
      lastname: name.lastname,
      email: undefined,
      numGuests: 1,
      guests: [{
        ...defaultGuest,
        ...(name.firstname && name.lastname && { firstname: name.firstname, lastname: name.lastname }),
      }],
      guestIndex: 0,
      error: undefined,
      isLoading: false,
      isExistingBooking: false,
    }))
  }

  if (isRSVPd) {
    return (
      <>
        <Typography
          variant="h3"
          textAlign="center"
          fontFamily="Dela Gothic One"
        >
          What would you like to do?
        </Typography>
        <Box display="flex" flexDirection="column" alignItems="center" mt="2rem">        
          <Button sx={{ fontFamily: "Bali", fontSize: "1.5rem" }} onClick={() => navigate(Links.Details)}>
            Update Details
            {/* TODO: Show a question that asks if they want to update or view summary. */}
          </Button>
          <Button sx={{ fontFamily: "Bali", fontSize: "1.5rem" }} onClick={() => navigate(Links.Summary)}>
            {/* TODO: Clears details in react and shows the group RSVP form */}
            View Summary
          </Button>
        </Box>
      </>
    )
  }
  
  return (
    <>
      <Typography
        variant="h4"
        component="h3"
        textAlign="center"
        fontFamily="Dela Gothic One"
      >
        Have you already been RSVP'd?
      </Typography>
      <Typography
        variant="h6"
        component="h3"
        textAlign="center"
        fontFamily="Dela Gothic One"
        mt="1rem"
      >
        We've found an RSVP with the same details.
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center" mt="2rem">        
        <Button sx={{ fontFamily: "Bali", fontSize: "1.5rem" }} onClick={() => setIsRSVPd(true)}>
          Yes, I have.
        </Button>
        <Button sx={{ fontFamily: "Bali", fontSize: "1.5rem" }} onClick={onIsNewBooking}>
          {/* TODO: Clears details in react and shows the group RSVP form */}
          No, I am RSVP'ing for the first time.
        </Button>
      </Box>
    </> 
  )
}