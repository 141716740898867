import Dexie from "dexie";

type ImageEntry = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  attending: string;
  message: string;
}

type TokenEntry = {
  id: string;
  token: string;
}

export class Database extends Dexie {
  // Declare implicit table properties.
  // (just to inform Typescript. Instanciated by Dexie in stores() method)
  rsvp: Dexie.Table<ImageEntry, string>; // string = type of the primkey
  //...other tables goes here...

  constructor () {
      super("Database");
      this.version(3).stores({
          rsvp: 'id, firstname, lastname, email, attending, message',
          //...other tables goes here...
      });
      // The following line is needed if your typescript
      // is compiled using babel instead of tsc:
      this.rsvp = this.table("rsvp");
  }
}