import { combineReducers, createStore, applyMiddleware, AnyAction } from "redux";
import thunk, { ThunkDispatch } from 'redux-thunk';
import { rsvpStore } from "./rsvp";
import { useDispatch as reduxUseDispatch } from "react-redux";

const reducers = combineReducers({
  rsvp: rsvpStore,
});

const store = createStore(reducers, applyMiddleware(thunk));

export const useDispatch = reduxUseDispatch as () => ThunkDispatch<ReturnType<typeof store.getState>, {}, AnyAction>;

export default store;