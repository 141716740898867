//@ts-nocheck
import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const ButtonIds = {
  USD: "buy_btn_1PB5WJKHEFuajnOK8BTMlab5",
  AUD: "buy_btn_1PB5O2KHEFuajnOKfZIact7i",
  GBP: "buy_btn_1PB5XtKHEFuajnOKiNS1vwuc",
  INR: "buy_btn_1PB5TWKHEFuajnOKqJ3vAjFa"
}

const stripePublicKey = "pk_live_51PB5HVKHEFuajnOKTpt1hfDc9jg1gqGRaGyJ9DSizU6cEjiXW5bhvRpXQZr5ECLeZohpIcPzRiO5PPrfPpwyRalE00AD3orkyr";

export const Gift = () => {

  const timezoneOffsetHours = -(new Date().getTimezoneOffset() / 60);
  const buttonId = timezoneOffsetHours >= 8 ? ButtonIds.AUD : timezoneOffsetHours >= 3 ? ButtonIds.INR : timezoneOffsetHours >= -2 ? ButtonIds.GBP : ButtonIds.USD;
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant='h1' fontFamily="Bali" textAlign="center">Wishing Well</Typography>
      <Typography variant='body2' textAlign="center" my="0.5rem" >
        Your presence at our wedding is a gift in itself. However, if you would like to contribute to our future together as a couple, you can contribute to our wishing well here.
      </Typography>
      <stripe-buy-button
        buy-button-id={buttonId}
        publishable-key={stripePublicKey}
      >
      </stripe-buy-button>
      {/* <Button onClick={() => window.location = "https://www.paypal.com/donate/?hosted_button_id=ZLKHGZM663V58"} size='small'>Or use PayPal</Button> */}
    </Box>
  )
}