import { Action } from "redux";
import {useSelector as useReduxSelector, TypedUseSelectorHook} from "react-redux";
import { ThunkAction as ReactThunkAction} from 'redux-thunk';
import { RSVPState } from "./rsvp";

export type RootState = {
  rsvp: RSVPState;
};

export type ThunkAction<ReturnType = void> =
  ReactThunkAction<ReturnType, RootState, {}, Action<string>>;

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
