import React from 'react';
import styled from "styled-components";
import { Box, Grid, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Route, Routes } from 'react-router-dom';
import { EnterName } from './EnterName';
import { Summary } from './Summary';
import { GroupRSVP } from './GroupRSVP';
import { Links } from '../links';
import { useIsLoading } from '../state/rsvp';
import { AddEmail } from './AddEmail';
import { HaveYouRSVPd } from './HaveYouRSVPd';
import { Gift } from './Gift';

export const Banner = styled(Box)<{ bgImage?: string, cover?: boolean, flexStart?: boolean }>`
  display: flex;
  justify-content: ${({ flexStart }) => flexStart ? "flex-start" : "center"};
  ${({ bgImage, cover }) => bgImage && cover
    ? `
      // box-shadow: inset 0 0 0 1000px #021816bb;
      background-image: url("/${bgImage}");
      background-size: cover;
    ` : bgImage ? `
      background-image: url("/${bgImage}");
      background-size: cover;
    ` : ""}
  background-color: #ffffff;
  width: 100%;
  min-height: 100vh;
`;

const Title = () => {
  const { breakpoints } = useTheme();
  const isSmall = useMediaQuery(breakpoints.down("sm"));
  const fontSize = isSmall ? "3.5rem" : "4.5rem";
  const isLoading = useIsLoading();
  return (
    <Grid item xs={12} style={{ marginTop: "0", justifyContent: "center", flexDirection: "column", alignItems: "center", display: "flex" }}>
      <Box display="flex" justifyContent="center">
        <Typography
          variant="h1"
          fontWeight={600}
          style={{
            fontFamily: "Dela Gothic One",
            fontSize,
            display: "inline",
            paddingBottom: "2rem",
            fontWeight: 600,
          }}
        >
          {/* Pari <GradientText variant="h1" display="inline" style={{ fontSize: "2rem", fontFamily: "LeJour" }}>and</GradientText> Aman */}
          RSVP
        </Typography>
      </Box>
      <Typography
        variant="h5"
        component="h2"
        textAlign="center"
        fontFamily="CMU"
        mb="1rem"
      >
        Seminyak, Bali
      </Typography>
      <Typography
        variant="h5"
        component="h2"
        textAlign="center"
        fontFamily="CMU"
        mb="2rem"
      >
        April 27-28 2024
      </Typography>
      {
        isLoading ? (
          <Typography
            variant="h5"
            component="h2"
            textAlign="center"
            fontFamily="CMU"
          >
            Loading...
          </Typography>
        ) : (
          <Routes>
            <Route path={Links.Details} element={<GroupRSVP />}/>
            <Route path={Links.Email} element={<AddEmail />}/>
            <Route path={Links.ExistingRSVP} element={<HaveYouRSVPd />}/>
            <Route path={Links.Summary} element={<Summary />}/>
            <Route path={Links.Home} element={<EnterName />}/>
          </Routes>   
        )
      }
    </Grid>
  );
};

export const Dashboard = () => {
  const { breakpoints, palette } = useTheme();
  const isLarge = useMediaQuery(breakpoints.up("lg"));
  const domain = window.location.hostname;
  if (domain.startsWith("gift.") || domain.includes("localhost")) {
    return (
      <Banner cover bgcolor={palette.background.default}>
        <Box sx={{
            position: "absolute",
            top: "calc(max(128px, 15%))",
            p: "5%",
            width: isLarge ? "50vw" : "90%",
            maxWidth: "80%",
            // background: `linear-gradient(180deg, #A6D19E, #08422700)`,
            height: "400px",
            // borderRadius: "32px"
          }}
        >
          <Gift/>
          {/* <Search /> */}
        </Box>
      </Banner>
    );
  }
  return (
    <Banner cover bgcolor={palette.background.default}>
      <Box sx={{
          position: "absolute",
          top: "calc(max(128px, 15%))",
          p: "5%",
          width: isLarge ? "50vw" : "90%",
          maxWidth: "80%",
          // background: `linear-gradient(180deg, #A6D19E, #08422700)`,
          height: "400px",
          // borderRadius: "32px"
        }}
      >
        <Title/>
        {/* <Search /> */}
      </Box>
    </Banner>
  );
}