import axios from "axios";
import { ThunkAction, useSelector } from "./types";
import { Database } from "./db";
import { useNavigate } from "react-router-dom";
import { Links } from "../links";

const db = new Database();

enum RSVPError {
  NoBooking = "NoBooking",
  DuplicateName = "DuplicateName",
  AlreadyExisting = "AlreadyExisting",
  SavingError = "SavingError"
}

type RSVPRecord = {
  attending: boolean;
  firstname: string;
  lastname: string;
  dietaryRequirements: string;
  message: string;
};

export type RSVPState = {
  firstname?: string;
  lastname?: string;
  email?: string;
  numGuests: number;
  guests: RSVPRecord[];
  guestIndex: number;
  error?: RSVPError;
  isLoading?: boolean;
  isExistingBooking: boolean;
};

export const defaultGuest: RSVPRecord = {
  attending: true,
  firstname: "",
  lastname: "",
  dietaryRequirements: "",
  message: "",
};


type SetRSVPName = {
  type: RSVPAction.SetRSVPName;
  firstname: string;
  lastname: string;
};
type SetIsExistingBooking = {
  type: RSVPAction.SetIsExistingBooking;
  isExistingBooking: boolean;
};
type SetEmail = {
  type: RSVPAction.SetEmail;
  email: string;
};

type SetGuestIndex = {
  type: RSVPAction.SetGuestIndex;
  index: number;
};

type SetGuestDetails = {
  type: RSVPAction.SetGuestDetails;
  details: RSVPRecord;
};

type SetAllGuests= {
  type: RSVPAction.SetAllGuests;
  guests: RSVPRecord[];
};

type SetError = {
  type: RSVPAction.SetError;
  error?: RSVPError;
};

type SetLoading = {
  type: RSVPAction.SetLoading;
  isLoading: boolean;
};

type SetState = {
  type: RSVPAction.SetState;
  state: Partial<RSVPState>;
};

type RSVPActions =
  | SetRSVPName
  | SetEmail
  | SetGuestIndex
  | SetGuestDetails
  | SetAllGuests
  | SetError
  | SetLoading
  | SetIsExistingBooking
  | SetState;

enum RSVPAction {
  SetRSVPName = "set-rsvp-name",
  SetIsExistingBooking = "set-is-existing-booking",
  SetEmail = "set-email",
  SetGuestIndex = "set-guest-index",
  SetGuestDetails = "set-guest-details",
  SetAllGuests = "set-all-guests",
  SetError = "set-error",
  SetLoading = "set-loading",
  SetState = "set-state",
};

export const initialState: RSVPState = { numGuests: 1 , guests: [], guestIndex: 0, isExistingBooking: false };

export const rsvpStore = (state: RSVPState = initialState, action?: RSVPActions): RSVPState => {
  switch(action?.type) {
    case RSVPAction.SetRSVPName:
      return { 
        ...state, 
        firstname: action.firstname, 
        lastname: action.lastname, 
        numGuests: 1,
        guestIndex: 0,
        guests: [{
          firstname: action.firstname, 
          lastname: action.lastname, 
          attending: true, 
          dietaryRequirements: "", 
          message: "" 
        }], 
      };
    case RSVPAction.SetEmail:
        return { ...state, email: action.email, isLoading: false };
    case RSVPAction.SetGuestIndex:
        return { ...state, guestIndex: Math.min(Math.max(0, action.index), state.guests.length - 1) };
    case RSVPAction.SetGuestDetails:
      const guests = [...state.guests];
      guests[state.guestIndex] = action.details;
      return { ...state, guests };
    case RSVPAction.SetAllGuests:
      return { ...state, guests: action.guests, numGuests: action.guests.length, guestIndex: 0, isLoading: false };
    case RSVPAction.SetError:
      return { ...state, error: action.error, isLoading: false };
    case RSVPAction.SetLoading:
      return { ...state, isLoading: action.isLoading };
    case RSVPAction.SetIsExistingBooking:
      return { ...state, isExistingBooking: action.isExistingBooking };
    case RSVPAction.SetState:
      return { ...state, ...initialState, ...action.state };
  }
  return state;
}

const setRSVPName = (firstname: string, lastname: string): SetRSVPName => ({
  type: RSVPAction.SetRSVPName,
  firstname,
  lastname,
});

export const setEmail = (email: string): SetEmail => ({
  type: RSVPAction.SetEmail,
  email,
});

export const setIsExistingBooking = (isExistingBooking: boolean): SetIsExistingBooking => ({
  type: RSVPAction.SetIsExistingBooking,
  isExistingBooking,
});

export const setGuestIndex = (index: number): SetGuestIndex => ({
  type: RSVPAction.SetGuestIndex,
  index,
});

export const setGuestDetails = (details: RSVPRecord): SetGuestDetails => ({
  type: RSVPAction.SetGuestDetails,
  details,
});

export const setAllGuests = (guests: RSVPRecord[]): SetAllGuests => ({
  type: RSVPAction.SetAllGuests,
  guests,
});

export const setError = (error?: RSVPError): SetError => ({
  type: RSVPAction.SetError,
  error,
});

export const setLoading = (isLoading = true): SetLoading => ({
  type: RSVPAction.SetLoading,
  isLoading,
});

export const setRSVPState = (state: Partial<RSVPState>): SetState => ({
  type: RSVPAction.SetState,
  state,
});

export const fetchRSVP = (firstname: string, lastname: string): ThunkAction => async (dispatch) => {
  dispatch(setRSVPName(firstname, lastname));
  dispatch(setLoading(true));
  try {
    const res = await axios.get<{ guest: RSVPRecord & { email: string }; additionalGuests: RSVPRecord[] }>(
      `${process.env.REACT_APP_BACKEND_URL}/user?firstname=${firstname}&lastname=${lastname}`
    );
    dispatch(setEmail(res.data.guest.email));
    dispatch(setAllGuests([res.data.guest, ...res.data.additionalGuests]));
    dispatch(setIsExistingBooking(true));
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.data.duplicates) {
        dispatch(setError(RSVPError.DuplicateName));
      } else {
        dispatch(setError(RSVPError.NoBooking));
      }
      console.log(e.response?.data?.msg);
    }
    dispatch(setIsExistingBooking(false));
  }
  dispatch(setLoading(false));
}

export const saveRSVP = (): ThunkAction => async (dispatch, getState) => {
  const rsvpState = getState().rsvp;
  if (!rsvpState.email || !rsvpState.guests.length) {
    return;
  }
  dispatch(setError(undefined));
  dispatch(setLoading(true));
  try {
    await axios.post<{ guest: RSVPRecord & { email: string }; additionalGuests: RSVPRecord[] }>(
      `${process.env.REACT_APP_BACKEND_URL}/rsvp`,
       {
        email: rsvpState.email,
        guests: rsvpState.guests,
       }
    );
    setError(undefined);
  } catch (e) {
    if (axios.isAxiosError(e)) {
      dispatch(setError(RSVPError.SavingError));
      console.log(e.response?.data?.msg);
    }
  }
  dispatch(setLoading(false));
}

export const useRSVPError = (): RSVPError | undefined =>
  useSelector((state) => state.rsvp.error);

export const useBookingName = (): { firstname: string | undefined; lastname: string | undefined} =>
  useSelector((state) => ({ firstname: state.rsvp.firstname, lastname: state.rsvp.lastname }));

export const useLastName = (): string | undefined =>
  useSelector((state) => state.rsvp.lastname);

export const useGuests = (): RSVPRecord[] =>
  useSelector((state) => state.rsvp.guests);

export const useGuestIndex = (): number =>
  useSelector((state) => state.rsvp.guestIndex);

export const useCurrentGuest = (): RSVPRecord | undefined =>
  useSelector((state) => state.rsvp.guests[state.rsvp.guestIndex || 0]);

export const useCurrentEmail = (): string | undefined =>
  useSelector((state) => state.rsvp.email);

export const useIsLoading = (): boolean | undefined =>
  useSelector((state) => state.rsvp.isLoading);

export const useIsExistingRSVP = (): boolean | undefined =>
  useSelector((state) => state.rsvp.isExistingBooking);

