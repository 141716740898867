import React from 'react';
import { Button, TextField, Typography } from "@mui/material";
import { setEmail, useCurrentEmail } from '../state/rsvp';
import { useNavigate } from "react-router-dom";
import { useDispatch } from '../state';
import { Links } from '../links';
import { Textbox } from './Textbox';

export const AddEmail = () => {
  const email = useCurrentEmail();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onRSVP = () => {
    navigate(Links.Details);
  }

  return (
    <>
      <Typography
        variant="h6"
        component="h3"
        textAlign="center"
        fontFamily="Dela Gothic One"
      >
        Enter your email.
      </Typography>
      <Textbox
        name='Email'
        placeholder='someone@outlook.com'
        type="email"
        value={email}
        style={{ margin: 1, width: "fit-content" }} 
        onChange={(e) => dispatch(setEmail(e.target.value))}
      />
      <Button
        onClick={onRSVP}
        disabled={!email?.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)}
        sx={{ fontFamily: "Bali", fontSize: "1.5rem" }}
      >
        Next
      </Button>
    </>
  )
}