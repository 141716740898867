import React from 'react';
import { Box } from "@mui/material";
import './App.css';
import { ThemeProvider } from "@mui/material/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import theme from "./theme";
// @ts-ignore-next-line
import smoothscroll from "smoothscroll-polyfill";
import { Provider } from 'react-redux';
import store from './state';
import { Dashboard } from './components/Dashboard';

// kick off the polyfill!
smoothscroll.polyfill();

const App = () => {
  return (
  <Box display="flex" flexDirection="column" alignItems="center">
    <Provider store={store}>
      <Router>
        <StyledThemeProvider theme={theme as any}>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route
              path="/*"
              element={
                <Dashboard/>
              }
            />
          </Routes>
        </ThemeProvider>
        </StyledThemeProvider>
      </Router>
    </Provider>
  </Box>
  );
};

export default App;
