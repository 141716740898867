import React from "react";
import styled from "styled-components";

export const Textbox = styled.input`
  caret-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: transparent;
  font-family: "Dela Gothic One";
  font-size: 2rem;
  text-align: center;
  width: 250px;
  border: none;
  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`

export const CustomTextArea = styled.textarea`
  caret-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: #122926;
  font-family: "Dela Gothic One";
  font-size: 1.5rem;
  border: none
`