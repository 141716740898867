import React from 'react';
import { Box, Button, Typography } from "@mui/material";
import { fetchRSVP, useBookingName } from '../state/rsvp';
import { useNavigate } from "react-router-dom";
import { useDispatch } from '../state';
import { Links } from '../links';
import { Textbox } from './Textbox';

export const EnterName = () => {

  const bookingName = useBookingName();
  const [firstname, setFirstname] = React.useState(bookingName.firstname || "");
  const [lastname, setLastname] = React.useState(bookingName.lastname || "");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onRSVP = () => {
    dispatch(fetchRSVP(firstname, lastname));
    navigate(Links.ExistingRSVP);
  }

  const onUpdate = () => {
    dispatch(fetchRSVP(firstname, lastname));
    navigate(Links.Summary);
  }

  return (
    <>
      <Typography
        variant="h6"
        component="h3"
        textAlign="center"
        fontFamily="Dela Gothic One"
      >
        Enter your name
      </Typography>
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        {/* <TextField
          name='First name'
          placeholder='First name'
          variant='filled'
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
          sx={{ m: 1 }} 
          InputProps={{ sx: { caretColor: palette.primary.main, color: palette.primary.main, bgcolor: "transparent", fontFamily: "Dela Gothic One", fontSize: "2rem" }}}
          autoFocus
        /> */}
        <Textbox 
          type="text" 
          name='First name' 
          placeholder='First name'
          value={firstname}
          onChange={(e) => setFirstname(e.target.value.trim())}
          style={{ margin: "1rem" }}
          autoFocus
        />
        <Textbox 
          type="text" 
          name='Last name' 
          placeholder='Last name'
          value={lastname}
          onChange={(e) => setLastname(e.target.value.trim())}
          style={{ margin: "1rem" }}
        />
      </Box>
      <Button
        onClick={onRSVP} disabled={!lastname || !firstname}
        sx={{ fontFamily: "Bali", fontSize: "1.5rem" }}
      >
        RSVP
      </Button>
      <Button
        onClick={onUpdate} disabled={!lastname || !firstname}
        sx={{ fontFamily: "Bali", fontSize: "1.5rem" }}
      >
        Update my RSVP
      </Button>
    </> 
  )
}