import React from 'react';
import {  Button, TextField, Typography } from "@mui/material";
import { IndividualRSVPForm } from './IndividualRSVPForm';
import { useBookingName, useCurrentEmail, useGuests, useRSVPError } from '../state/rsvp';
import { useNavigate } from 'react-router-dom';
import { Links } from '../links';

export const Summary = () => {

  const error = useRSVPError();
  const email = useCurrentEmail();
  const guests = useGuests();
  const navigate = useNavigate();

  if (!error && !email) {
    navigate(Links.Home);
  }

  if (error) {
    return (
      <>
        <Typography
          variant="h6"
          component="h3"
          textAlign="center"
          fontFamily="Dela Gothic One"
        >
          Sorry, we couldn't find you in our system.
        </Typography>
        <Button
          onClick={() => navigate(Links.Home)} 
          sx={{ fontFamily: "Bali", fontSize: "1.5rem" }}
        > Go Back </Button>
      </>
    )
  }

  return (
    <>
      <Typography
        variant="h6"
        component="h3"
        textAlign="center"
        fontFamily="Dela Gothic One"
      >
        Guests
      </Typography>
      <TextField
        name='Email'
        placeholder='someone@outlook.com'
        value={email}
        variant='filled'
        sx={{ m: 1 }} 
        disabled
        InputProps={{ sx: { bgcolor: "white" }}}
      />
      {/* {guests.map((guest, i) => (
        <IndividualRSVPForm key={i} />
      ))} */}
      <Typography variant='h6' textAlign="center">
        You successfully have RSVP'd for {guests.length} {guests.length > 1 ? "guests" : "guest"}.
      </Typography>
      <Button onClick={() => navigate(Links.Details)} sx={{ fontFamily: "Bali", fontSize: "1.5rem" }}>
        Update Details
      </Button>
      <Button onClick={() => navigate(Links.Home)} sx={{ fontFamily: "Bali", fontSize: "1.5rem" }}>
        Back
      </Button>
    </> 
  )
}