import { createTheme, responsiveFontSizes }  from "@mui/material/styles";
import { Shadows } from "@mui/material/styles/shadows";

export const bgWhite = "#ffffff";
export const bgLightGreen = "#CBE2C8";

export const darkishGreen = "#246133";
export const inverseText = "#021816";

export const pink = "#BD6C9B";

export const darkGreen = "#021816";
export const lightGreen = "#7ED957";


const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: pink,
      light: pink,
    },
      secondary: {
      main: bgWhite,
      light: lightGreen,
    },
    text: {
      primary: darkGreen,
      secondary: darkishGreen,
    },
    background: {
      default: darkGreen,
      paper: bgLightGreen,
    },
    divider: pink,
    common: {
      black: darkGreen,
      white: bgWhite,
    }
  },
  typography: {
    fontFamily: "Inter, Helvetica, sans-serif",

  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: bgWhite
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: darkGreen,
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        contained: {
          color: bgWhite,
        },
        containedPrimary: {
          backgroundColor: pink,
        },
        containedSecondary: {
          backgroundColor: lightGreen,
        },
        outlined: {
          backgroundColor: bgWhite,
        },
        outlinedPrimary: {
          color: pink,
        },
        outlinedSecondary: {
          color: lightGreen,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        }
      }
    }
  },
  shadows: Array(25).fill("none") as Shadows,
});

const reponsiveTheme = responsiveFontSizes(theme);

export default reponsiveTheme;