import React from 'react';
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch } from 'react-redux';
import { setGuestDetails, useCurrentGuest } from '../state/rsvp';
import { CustomTextArea, Textbox } from './Textbox';

export const IndividualRSVPForm = () => {

  const dispatch = useDispatch();
  const theme = useTheme();
  const guest = useCurrentGuest();
  const breakpoints = theme.breakpoints;
  const isSmall = useMediaQuery(breakpoints.down("sm"));
  if (!guest) {
    return null;
  }

  return (
    <>
      <Typography
        variant="h6"
        component="h3"
        textAlign="center"
        fontFamily="Dela Gothic One"
      >
        Are you attending?
      </Typography>
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        <Button
          onClick={() => dispatch(setGuestDetails({ ...guest, attending: true }))}
          sx={{ fontFamily: "Bali", fontSize: "1.5rem", color: guest.attending ? "auto" : theme.palette.secondary.dark }}
        >
          Yes
        </Button>
        <Button
          onClick={() => dispatch(setGuestDetails({ ...guest, attending: false }))}
          sx={{ fontFamily: "Bali", fontSize: "1.5rem", color: guest.attending ? theme.palette.secondary.dark : "auto" }}
        >
          No
        </Button>
      </Box>
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        {/* TODO: Disable editing for first entry */}
        <Textbox
          name='First name'
          placeholder='First name'
          value={guest.firstname}
          style={{ margin: 1 }}
          onChange={(e) => dispatch(setGuestDetails({ ...guest, firstname: e.target.value}))}
        />
        <Textbox
          name='Last name'
          placeholder='Last name'
          style={{ margin: 1 }}
          value={guest.lastname}
          onChange={(e) => dispatch(setGuestDetails({ ...guest, lastname: e.target.value}))}
        />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant='h6' textAlign="left" alignSelf="flex-start" fontFamily="CMU" mt="1rem">
          Dietary restrictions
        </Typography>
        <CustomTextArea
          name='Dietary restrictions'
          placeholder='Vegetarian, Jain, etc.'
          style={{ fontSize: isSmall ? "1rem" : "1.5rem" }}
          rows={2}
          value={guest.dietaryRequirements}
          onChange={(e) => dispatch(setGuestDetails({ ...guest, dietaryRequirements: e.target.value}))}
        />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant='h6' textAlign="left" alignSelf="flex-start" fontFamily="CMU" mt="1rem">
          Additional notes
        </Typography>
        <CustomTextArea
          name='Notes'
          placeholder='Anything else we should know?'
          style={{ fontSize: isSmall ? "1rem" : "1.5rem" }}
          rows={4}
          value={guest.message}
          onChange={(e) => dispatch(setGuestDetails({ ...guest, message: e.target.value}))}
        />
      </Box>
      
    </> 
  )
}