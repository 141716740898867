import React, { useEffect, useState } from 'react';
import { Button, Typography } from "@mui/material";
import { IndividualRSVPForm } from './IndividualRSVPForm';
import { defaultGuest, saveRSVP, setAllGuests, setGuestIndex, useCurrentEmail, useCurrentGuest, useGuestIndex, useGuests, useIsLoading } from '../state/rsvp';
import { useDispatch } from '../state';
import { useNavigate } from 'react-router-dom';
import { Links } from '../links';
import { Textbox } from './Textbox';

export const GroupRSVP = () => {
  const guests = useGuests();
  const index = useGuestIndex();
  const email = useCurrentEmail();
  const dispatch = useDispatch();
  const allGuests = useGuests();
  const currentGuest = useCurrentGuest();
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      navigate(Links.Home);
    }
  }, [email, navigate])

  const [showGuestForm, setShowGuestForm] = useState(false);

  if (showGuestForm) {

    const onSubmit = () => {
      dispatch(saveRSVP());
      navigate(Links.Summary);
    }
    return (
      <>
       <Typography
          variant="h6"
          component="h3"
          textAlign="center"
          fontFamily="Dela Gothic One"
        >
          Details
        </Typography>
        <IndividualRSVPForm />
        <Button
          sx={{ fontFamily: "Bali", fontSize: "1.5rem" }}
          onClick={() => index === 0 ? setShowGuestForm(false) : dispatch(setGuestIndex(index - 1))}
        >
          Back
        </Button>
        {index < guests.length - 1 ? (
          <Button
            sx={{ fontFamily: "Bali", fontSize: "1.5rem" }}
            onClick={() => dispatch(setGuestIndex(index + 1))}
            disabled={!currentGuest || !currentGuest.firstname || !currentGuest.lastname}
          >
            Next
          </Button>
        ) : (
          <Button
            sx={{ fontFamily: "Bali", fontSize: "1.5rem" }}
            onClick={onSubmit}
            disabled={!currentGuest || !currentGuest.firstname || !currentGuest.lastname}
          >
            Done
          </Button>
        )}
      </>
    )
  }

  return (
    <>
      <Typography
        variant="h6"
        component="h3"
        textAlign="center"
        fontFamily="Bali"
      >
        Guests
      </Typography>
      <Textbox
        id="outlined-number"
        type="number"
        value={guests.length || 1}
        min={1}
        max={10}
        style={{ minWidth: "200px", width: "fit-content" }}
        onChange={(e) => e.target.value &&  dispatch(
          setAllGuests(Array.from(Array(parseInt(e.target.value)).keys()).map((_, i) => allGuests[i] || defaultGuest))
        )}
      />
      <Button
        sx={{ fontFamily: "Bali", fontSize: "1.5rem" }}
        onClick={() => setShowGuestForm(true)}
      >
        Next
      </Button>
    </> 
  )
}